<template>
  <SaveCategory
    @saveDetail="saveCategory"
    @cancelCreate="cancelCreate"
    :categoryDetail="categoryDetail"
    :update="false"
  />
</template>

<script>
  import SaveCategory from "../../../components/WebStore/categories/add"
  import { mapActions } from 'vuex';

  export default {
    name: 'Add Category',
    components:{
      SaveCategory
    },
    data: () => ({
      categoryDetail: {
        name: '',
        parentCategory: '',
        productDescription: '',
        matchingCondition: '',
        conditions: [],
        image: '',
        isPopular: false,
      }
    }),
    methods:{
      ...mapActions("category", ["addCategory"]),
      ...mapActions("ecommerce",["algoliaSync"]),
      saveCategory(data){
        let self=this;
        this.addCategory(data).then(res => {
          self.algoliaSync()
          self.$vs.notify({
            title: "Category Add",
            text: "Category added successfully",
            color: "success",
          });
          self.$vs.loading.close();
          this.$router.push({ name: 'AdminCategoryManagement'})
        });
      },
      cancelCreate() {
        this.$router.push({ name: "AdminCategoryManagement"});
      },
    }
  };
</script>
